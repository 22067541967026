import React, { useState } from 'react';

const CalendarSelector = ({ onSelectDay }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const nextMonth = () => {
        const next = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
        setCurrentMonth(new Date(next));
    };

    const prevMonth = () => {
        const prev = new Date(currentMonth.setMonth(currentMonth.getMonth() - 1));
        setCurrentMonth(new Date(prev));
    };

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const daysInMonth = getDaysInMonth(currentMonth.getMonth(), currentMonth.getFullYear());

    // Corrección: Ajustar el primer día del mes correctamente.
    const firstDayIndex = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
    const offset = firstDayIndex === 0 ? 6 : firstDayIndex - 1; // Ajuste para que lunes sea 0
    const days = Array.from({ length: offset }).concat(
        Array.from({ length: daysInMonth }, (_, i) => i + 1)
    );

    const handleSelectDay = (day) => {
        if (!day) return;
        const selectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        const formattedDate = selectedDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
        onSelectDay(formattedDate);
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <button onClick={prevMonth}>&lt;</button>
                <span>{currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}</span>
                <button onClick={nextMonth}>&gt;</button>
            </div>
            <div className="calendar-grid">
                {['L', 'M', 'X', 'J', 'V', 'S', 'D'].map((day, index) => (
                    <div key={index} className="calendar-day-name">{day}</div>
                ))}
                {days.map((day, index) => (
                    <div key={index} className="calendar-day" onClick={() => handleSelectDay(day)}>
                        {day || ''}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarSelector;
