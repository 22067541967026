import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../css/ClassItem.css";
import { useAuth } from "../contexts/AuthContext";
import CustomDialog from "./dialogs/CustomDialog";
import Swal from "sweetalert2";
import UserInfoModal from "./UserInfoModal";

const ClassItem = ({ classItem, onReserve, onCancel, onDelete }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [clients, setClients] = useState([]);
  const [isUserReserved, setIsUserReserved] = useState(false);
  const { user } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${apiUrl}api/classes/${classItem.id}/clients`,
        config
      );
      setClients(response.data);

      // Verifica si el usuario actual está en la lista de clientes
      const currentUser = localStorage.getItem("userId");
      setIsUserReserved(
        response.data.some((client) => client.id === parseInt(currentUser))
      );
    } catch (error) {
      console.error("Error al obtener los clientes:", error);
    }
  }, [apiUrl, classItem.id]);

  useEffect(() => {
    fetchClients();
  }, [classItem.id, fetchClients]);

  // Método para cuando un Admin o Entrenador hace clic en un avatar de un cliente
  const handleClientClick = (client) => {
    if (user.role === "Admin" || user.role === "Entrenador") {
      // Llamar al método onClientClick y pasar los datos del cliente
      onClientClick(client);
    } else if (client.id === parseInt(user.userId)) {
      // Si el cliente es el usuario registrado, mostrar el mensaje de cancelar reserva
      handleCancel();
    }
  };

  const handleCancel = async () => {
    if (isUserReserved) {
      Swal.fire({
        title: "",
        text: "¿Quieres cancelar tu reserva?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffcc00",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cancélala",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onCancel(classItem.id);
          fetchClients();
        }
      });
    }
  };

  const onClientClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleViewProfile = () => {
    // Aquí puedes redirigir a la página del perfil del usuario
    console.log('Ver perfil del usuario', selectedUser.username);
  };

  const handleReserve = async (e) => {
    e.stopPropagation();
    await onReserve(classItem.id);
    fetchClients(); // Refresca la lista de clientes después de reservar
  };

  const handleDeleteClass = () => {
    setShowDialog(true); // Mostrar el diálogo personalizado
  };

  const handleDialogClose = async (option) => {
    setShowDialog(false); // Ocultar el diálogo
    if (option === "deleteOne") {
      await onDelete(classItem.id, false); // Solo borrar la clase actual
    } else if (option === "deleteAll") {
      await onDelete(classItem.id, true); // Borrar la clase y las recurrentes
    }
  };

  return (
    <div className="class-item">
      <div className="class-time">
        <p>
          <span
            className={`class-title ${
              classItem.title === "Fitboxing"
                ? "class-title-fitboxing"
                : "class-title-default"
            }`}
          >
            {classItem.title}
          </span>{" "}
          - De {classItem.start_time} a {classItem.end_time} | {clients.length}/
          {classItem.max_attendees}
        </p>
        {(user?.role === "Admin" || user?.role === "Entrenador") && (
          <button className="delete-class-button" onClick={handleDeleteClass}>
            <i className="fas fa-trash"></i> {/* Icono para eliminar clase */}
          </button>
        )}
      </div>
      <div className="class-details">
        <div className="clients-list">
          {clients.length > 0 ? (
            clients.map((client, index) => (
              <div
                key={index}
                className="client-avatar"
                onClick={() => handleClientClick(client)}
              >
                <img
                  src={`${client?.profilepicture}`}
                  alt={client.first_name}
                  loading="lazy"
                  onError={(e) =>
                    (e.target.src = "https://via.placeholder.com/50")
                  }
                />
              </div>
            ))
          ) : (
            <p>No hay clientes apuntados.</p>
          )}
        </div>
        {!isUserReserved && (
          <button className="reserve-button" onClick={handleReserve}>
            <i className="fas fa-plus"></i>
          </button>
        )}
      </div>

      {showDialog && (
        <CustomDialog
          title="Confirmar Eliminación"
          message="¿Estás seguro de que deseas eliminar esta clase?"
          buttons={[
            { text: "Sí", onClick: () => handleDialogClose("deleteOne") },
            {
              text: "Sí y borrar recurrentes",
              onClick: () => handleDialogClose("deleteAll"),
            },
            {
              text: "Cancelar",
              onClick: () => setShowDialog(false),
              type: "cancel",
            },
          ]}
        />
      )}

      {showModal && selectedUser && (
        <UserInfoModal
          onClose={handleCloseModal}
          onViewProfile={handleViewProfile}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default ClassItem;
