import React, { createContext, useState, useContext, useEffect } from 'react';
import { subscribeToPush } from '../utils/subscribeToPush';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Estado inicial como null
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Verifica si hay un token en localStorage al cargar la aplicación
        const token = localStorage.getItem('token');
        const storedUserId = localStorage.getItem('userId');
        const storedUsername = localStorage.getItem('username');
        const storedTipoSuscripcion = localStorage.getItem('tipo_suscripcion');
        const storedRole = localStorage.getItem('role');
        const storedProfilePicture = localStorage.getItem('profilepicture');

        if (token && storedUserId && storedUsername) {
            setIsAuthenticated(true);
            setUser({
                userId: storedUserId,
                username: storedUsername,
                tipoSuscripcion: storedTipoSuscripcion,
                role: storedRole,
                profilepicture: storedProfilePicture,
            });
        } else {
            setIsAuthenticated(false); // Si no hay token, no está autenticado
        }
    }, []);

    const login = async (userId, username, tipoSuscripcion, role, profilepicture) => {
        setIsAuthenticated(true);
        setUser({
            userId,
            username,
            tipoSuscripcion,
            role,
            profilepicture
        });
    
        // 👉 Llama a la suscripción push
        try {
            await subscribeToPush();
        } catch (err) {
            console.warn("❌ Error al registrar suscripción push:", err);
        }
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('tipo_suscripcion');
        localStorage.removeItem('role');
        localStorage.removeItem('profilepicture');
    };

    const updateUser = (updatedUser) => {
        setUser(updatedUser);
        localStorage.setItem('profilepicture', updatedUser.profilepicture); // Actualiza el localStorage
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, user, updateUser }}>
            {children}
        </AuthContext.Provider>
    );
};
