export const subscribeToPush = async () => {
    if (!('serviceWorker' in navigator)) return;
  
    const registration = await navigator.serviceWorker.ready;
  
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.warn("🔕 El usuario no concedió permiso para notificaciones");
      return;
    }
  
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BIGacoZWhYAp8AIAdWqJVfeRixvzsM7CiIQmzW1fDtcVMP1vKj3LDkGFeTcKtsJtU-8hP8YlEKJmodyDPKL9bIw',
    });
  
    await fetch(`${process.env.REACT_APP_API_URL}api/notifications/subscribe`, {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    localStorage.setItem("pushEnabled", "true"); // ← Aquí
    console.log("✅ Suscripción push enviada al backend");
    return(true)
  };
  