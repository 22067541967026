import React from "react";
import "../css/TermsModal.css";

const TermsModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Términos y Condiciones</h2>
        <div className="modal-body">
          <h3>Introducción</h3>
          <p>
            Bienvenido a Wild Fitboxing, centro de entrenamiento personal y en
            grupo presencial.
          </p>
          <p>
            Nos reservamos el derecho de actualizar, modificar o cambiar estas
            políticas, términos y condiciones en cualquier momento y sin previo
            aviso. Es responsabilidad del usuario revisar periódicamente estos
            términos y condiciones para estar al tanto de cualquier cambio.
          </p>

          <h3>Información del sitio</h3>
          <p>
            El sitio web en el que te encuentras tiene como finalidad la gestión
            de los datos del usuario, la opción de pago telemático de los
            servicios prestados, e información sobre entrenamiento, nutrición y
            hábitos saludables. Estas políticas, términos y condiciones se
            aplican a todos los productos y servicios ofertados en nuestra web y
            contratados de manera presencial en Wild Fitboxing Aldaia.
          </p>

          <h3>Precios y pagos</h3>
          <p>
            Todos nuestros precios quedan reflejados en Tarifas Oficiales 2024.
          </p>
          <p>
            Todos los precios de servicios y productos ofertados y que aparecen
            en nuestro sitio web están reflejados en euros (€) e incluyen el
            impuesto al valor agregado (IVA).
          </p>

          <h4>Aceptamos las siguientes formas de pago:</h4>
          <ul>
            <li>Pago en efectivo</li>
            <li>Pago por tarjeta</li>
            <li>Pago por Bizum</li>
            <li>Domiciliación bancaria vía Stripe</li>
          </ul>

          <p>
            El pago de los servicios prestados por Wild Fitboxing se abonará
            mediante la forma de pago estipulada que será el mismo día que se
            realizó el pago por primera vez, siendo así una CUOTA FIJA MENSUAL
            durante la permanencia contratada, excepto el PAGO ÚNICO (packs de
            sesiones). El socio tendrá la obligación de responder a dichos pagos
            entre los días estipulados en el presente contrato.
          </p>

          <h4>Condiciones especiales:</h4>
          <ul>
            <li>
              Si el socio contrata el servicio entre los días 1 y 15 del mes, no
              se aplicará prorrateo alguno.
            </li>
            <li>
              Si el socio contrata algún servicio, tanto de entrenamiento
              personal como entrenamiento en grupo, se le aplicará un prorrateo
              de la parte proporcional contratada.
            </li>
            <li>
              Domiciliación vía Stripe: Las cuotas son mensuales mediante cargo
              en tarjeta vía Stripe, efectuándose tales domiciliaciones en la
              tarjeta bancaria del socio indicada previamente. No existe opción
              de reembolso del recibo aunque no se haya asistido al centro.
              Solamente se reembolsará el recibo si ha habido previo aviso de la
              baja antes del día 20 del mes previo.
            </li>
          </ul>

          <h4>Incumplimientos de pagos:</h4>
          <p>
            El usuario pendiente de pago de cualquier cantidad incurre en mora
            automática sin necesidad de previo requerimiento. Wild Fitboxing
            remitirá el expediente a sus servicios jurídicos de reclamación para
            adoptar las medidas que legalmente amparen a Wild Fitboxing. En
            cualquier caso, el socio responderá de los gastos generados por la
            devolución de los recibos y de la indemnización de los daños y
            perjuicios que legalmente procedan por dicho incumplimiento. La
            penalización por devolución del pago domiciliado será de 5 €. Este
            importe se sumará a la cuota devuelta.
          </p>

          <h4>Revisión de cuota:</h4>
          <p>
            El importe del contrato únicamente se revisará con carácter anual,
            transcurrido un año desde su formalización, sea cual sea su
            duración, y con el único fin de aplicar al importe mensual la
            variación del IPC anual producida en los 12 meses anteriores. Para
            ello, se tomará el último índice publicado oficialmente (INE). El
            retraso en su aplicación no presupondrá nunca una renuncia a aplicar
            dicha variación.
          </p>

          <h3>Política de devolución y desistimiento de contrato</h3>

          <h4>Resolución anticipada de contrato:</h4>
          <p>
            El incumplimiento del contrato por cualquiera de las partes sin
            ninguna causa debidamente justificada y demostrada supondrá, en el
            caso del alumno, el pago total de la mensualidad y una penalización
            de 10 € por mes realizado.
          </p>

          <h4>Cambio y sustitución de profesional:</h4>
          <p>
            La tarifa contratada no va sujeta a un entrenador determinado.
            Durante el presente contrato, Wild Fitboxing podrá cambiar o
            sustituir a un entrenador personal en situaciones de baja laboral,
            enfermedad o vacaciones, garantizando así el desarrollo
            ininterrumpido del servicio contratado. No será causa de rescisión
            de contrato dicho cambio o sustitución de entrenador.
          </p>

          <h3>Propiedad Intelectual</h3>
          <p>
            Todo el contenido de este sitio web, incluyendo imágenes, textos y
            diseños, es propiedad de Wild Fitboxing y está protegido por las
            leyes de propiedad intelectual. Está prohibida la reproducción,
            distribución, exhibición, transmisión o explotación de cualquier
            contenido de nuestra tienda virtual sin nuestro permiso expreso por
            escrito.
          </p>

          <h3>Privacidad y protección de datos</h3>
          <p>
            Nos comprometemos a proteger la privacidad de nuestros usuarios.
            Para obtener más información sobre cómo manejamos su información
            personal, consulte nuestra Política de protección de datos.
          </p>

          <ul>
            <li>
              <strong>Responsable del tratamiento:</strong> Wild Fitboxing
            </li>
            <li>
              <strong>Dirección:</strong> Pablo Iglesias n2, Aldaia, Valencia
            </li>
            <li>
              <strong>Finalidad:</strong> Sus datos serán usados para poder
              atender sus solicitudes y prestarle nuestros servicios.
            </li>
            <li>
              <strong>Publicidad:</strong> Solo le enviaremos publicidad con su
              autorización previa, que podrá facilitarnos mediante la casilla
              correspondiente establecida al efecto.
            </li>
            <li>
              <strong>Legitimación:</strong> Únicamente trataremos sus datos con
              su consentimiento previo.
            </li>
            <li>
              <strong>Destinatarios:</strong> Solo el personal autorizado podrá
              tener conocimiento de la información que le pedimos.
            </li>
            <li>
              <strong>Derechos:</strong> Tiene derecho a saber qué información
              tenemos sobre usted, corregirla y eliminarla.
            </li>
          </ul>

          <p>
            Los padres de los menores de edad deberán firmar una autorización
            para que sus hijos puedan asistir sin su compañía.
          </p>

          <p>
            Todo cliente de Wild Fitboxing acepta ser grabado o fotografiado con
            el único fin de promocionar nuestro negocio. Si alguien no desea ser
            grabado, deberá comunicarlo oficialmente.
          </p>

          <h3>Limitación de responsabilidad</h3>
          <p>
            No nos hacemos responsables de ningún daño directo, indirecto,
            incidental, especial o consecuente que pueda resultar del uso o la
            imposibilidad de uso de nuestro sitio web.
          </p>

          <p>
            La información compartida en nuestro sitio web no está personalizada
            a las necesidades de cada socio. Solo los documentos compartidos
            individualmente por cada profesional de Wild Fitboxing con sus
            clientes son personalizados y adaptados a las características del
            individuo.
          </p>

          <p>
            No garantizamos que nuestro sitio web/app esté libre de errores o
            virus, o que su acceso sea ininterrumpido.
          </p>

          <h3>Ley aplicable y jurisdicción</h3>
          <p>
            Estas políticas, términos y condiciones se regirán e interpretarán
            de acuerdo con las leyes de España. Cualquier disputa que surja en
            relación con estas políticas, términos y condiciones se resolverá
            exclusivamente en los tribunales de Valencia, España.
          </p>

          <h3>Disposiciones generales</h3>
          <p>
            Estas políticas, términos y condiciones constituyen el acuerdo
            completo entre el usuario y Wild Fitboxing con respecto al uso de
            nuestros servicios presenciales y la información almacenada en el
            sitio. Si alguna disposición se considera inválida, esa disposición
            se interpretará de manera consistente con la ley aplicable. Las
            demás disposiciones seguirán siendo válidas.
          </p>

          <p>
            Puede obtener más información sobre nuestro Aviso Legal y Políticas
            de Cookies.
          </p>
        </div>
        <div className="modal-footer">
          <button onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
