import React, { useEffect, useState } from "react";
import axios from "axios";
import UserProfile from "./UserProfile";
import { useAuth } from "../contexts/AuthContext";
import UserCardList from "./UserCardList";
import "../css/UserProfile.css";
import SubscriptionList from "./SubscriptionList";
import UserClassesPage from "./UserClassesPage";
import Swal from "sweetalert2";
import { FaExclamationCircle } from "react-icons/fa";
import AcceptTermsModal from "./AcceptTermsModal";
import TermsModal from "./TermsModal";

const UserProfilePage = () => {
  const { user, logout, updateUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("datos"); // Estado para manejar el tab activo
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Estado para el menú desplegable móvil
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showTermsModalView, setShowTermsModalView] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${apiUrl}api/users/profile`, config);
        setUserData(response.data);
        setLoading(false);

        // Si el usuario no ha aceptado los términos, mostramos el modal
        if (!response.data.accept_terms) {
          setShowTermsModal(true);
        }
      } catch (error) {
        setError("Error al obtener el perfil del usuario");
        setLoading(false);
        logout();
      }
    };

    fetchUserProfile();
  }, [logout, apiUrl]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "datos":
        return <UserProfile user={userData} />;
      case "misClases":
        return <UserClassesPage user={userData} />; // Contenido para la tab "Mis clases"
      case "modificarUsuarios":
        return <UserCardList />; // Contenido para la tab "Modificar usuarios"
      case "misPagos":
        return <SubscriptionList userSubscription={user.tipoSuscripcion} />; // Contenido para la tab "Mis pagos"
      default:
        return null;
    }
  };

  const handleAcceptTerms = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      // Actualizar el campo accept_terms a true
      const response = await axios.put(
        `${apiUrl}api/users/accept-terms`,
        { accept_terms: true },
        config
      );

      setShowTermsModal(false); // Oculta el modal
      updateUser({ ...user, accept_terms: true }); // Actualiza el contexto de usuario
      Swal.fire({
        icon: "success",
        title: "Términos aceptados",
        text: response.data.message,
        confirmButtonColor: "#ffcc00",
      });
    } catch (error) {
      console.error("Error al aceptar los términos:", error);
    }
  };

  const handleLogout = () => {
    logout(); // Llamada a la función de logout
    window.location.href = "/"; // Redirige a la página principal o a la de login después de cerrar sesión
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setIsMobileMenuOpen(false); // Cerrar el menú al hacer clic en una pestaña
  };

  const resizeImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          quality
        );
      };

      img.onerror = reject;
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Redimensionar la imagen antes de subirla
        const resizedBlob = await resizeImage(file, 150, 150, 0.7); // Tamaño máximo 150x150px, calidad 70%

        const formData = new FormData();
        formData.append("image", resizedBlob, file.name); // Agrega la imagen redimensionada al FormData

        try {
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", // Importante: multipart/form-data para enviar archivos
            },
          };

          const response = await axios.post(
            `${apiUrl}api/users/profile/picture`,
            formData,
            config
          );

          if (response.status === 200) {
            // Actualiza el estado del usuario en el contexto
            const updatedUser = {
              ...user,
              profilepicture: response.data.imageUrl,
            }; // Asegúrate de que el backend devuelva la URL de la imagen
            updateUser(updatedUser); // Llama a la función para actualizar el contexto

            Swal.fire({
              icon: "success",
              title: "",
              text: response.data.message,
              confirmButtonColor: "#ffcc00",
            });
          }
        } catch (error) {
          console.error("Error al subir la imagen:", error);

          Swal.fire({
            icon: "error",
            title: "",
            text: "Error al subir la imagen",
            confirmButtonColor: "#ffcc00",
          });
        }
      } catch (error) {
        console.error("Error al redimensionar la imagen:", error);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error al procesar la imagen",
          confirmButtonColor: "#ffcc00",
        });
      }
    }
  };
  return (
    <div className="user-profile-page">
      {/* Mostrar el modal de términos si no ha aceptado los términos */}
      {showTermsModal && (
        <AcceptTermsModal
          onAccept={handleAcceptTerms}
          onLogout={handleLogout}
        />
      )}
      {showTermsModalView && (
        <TermsModal onClose={() => setShowTermsModalView(false)} /> // Muestra el modal si está activo
      )}
      <div className="sidebar">
        <div className="profile-pic-container">
          <div className="profile-pic">
            <label htmlFor="fileInput">
              <img
                src={`${user.profilepicture}`}
                alt="Profile"
                loading="lazy"
                onError={(e) =>
                  (e.target.src = "https://ui-avatars.com/api/?name=Cliente&background=random")
                }
              />
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>

          {/* Icono de advertencia fuera del contenedor de la imagen */}
          {user.profilepicture === "null" && (
            <FaExclamationCircle className="warning-icon-outside" />
          )}
        </div>
        <div className="username-and-menu">
          <h2 className="username">{userData?.username}</h2>
          <button
            className="mobile-menu-toggle"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <i className="fas fa-bars"></i> {/* Icono de hamburguesa */}
          </button>
        </div>

        {/* Menú desplegable que solo se muestra en dispositivos móviles */}
        <ul className={`tabs ${isMobileMenuOpen ? "open" : ""}`}>
          <li
            className={activeTab === "datos" ? "active" : ""}
            onClick={() => handleTabClick("datos")}
          >
            Datos
          </li>
          {user?.role !== "Admin" && (
            <li
              className={activeTab === "misClases" ? "active" : ""}
              onClick={() => handleTabClick("misClases")}
            >
              Mis clases
            </li>
          )}
          {/* Mostrar la opción "Modificar usuarios" solo si el usuario es Admin */}
          {user?.role === "Admin" && (
            <li
              className={activeTab === "modificarUsuarios" ? "active" : ""}
              onClick={() => handleTabClick("modificarUsuarios")}
            >
              Modificar usuarios
            </li>
          )}
          {user?.role !== "Admin" && (
            <li
              className={activeTab === "misPagos" ? "active" : ""}
              onClick={() => handleTabClick("misPagos")}
            >
              Suscripción
            </li>
          )}
          <li onClick={() => setShowTermsModalView(true)}>
            Términos y condiciones
          </li>
          <li className="logout-tab" onClick={handleLogout}>
            Cerrar sesión
          </li>
        </ul>
      </div>
      <div className="content-area">{renderTabContent()}</div>
    </div>
  );
};

export default UserProfilePage;
