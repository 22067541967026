import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/UserRanking.css';
import { FaCrown, FaInfoCircle } from 'react-icons/fa'; // Importa los íconos
import ScoreSystemModal from './ScoreSystemModal';


const UserRanking = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false); // Estado para controlar el modal

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/users/all`, config);

        // Filtrar los usuarios de tipo "Cliente" y ordenarlos por puntuación y username
        const filteredUsers = response.data
          .filter((user) => user.role === 'Cliente')
          .sort((a, b) => {
            if (b.score === a.score) {
              return a.username.localeCompare(b.username); // Ordenar alfabéticamente si tienen la misma puntuación
            }
            return b.score - a.score; // Ordenar por puntuación
          });

        setUsers(filteredUsers);
      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };

    fetchUsers();
  }, []);

  const getCrownIcon = (rank) => {
    switch (rank) {
      case 1:
        return <FaCrown style={{ color: 'gold' }} />;
      case 2:
        return <FaCrown style={{ color: 'silver' }} />;
      case 3:
        return <FaCrown style={{ color: '#cd7f32' }} />; // Bronce
      default:
        return null;
    }
  };

  let currentRank = 0; // Inicializa el puesto actual
  let lastScore = null; // Para rastrear el puntaje anterior

  return (
    <div className="ranking-container">
      <button
        className="info-btn"
        onClick={() => setShowModal(true)} // Abrir el modal
        >
        <FaInfoCircle  />
      </button>
        <h2>Ranking WILD</h2>

      <table className="ranking-table">
        <thead>
          <tr>
            <th>Puesto</th>
            <th>Usuario</th>
            <th>Puntuación</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            if (lastScore !== user.score) {
              currentRank = currentRank + 1; // Actualiza el puesto
            }
            lastScore = user.score; // Actualiza el último puntaje

            return (
              <tr key={user.id} className="ranking-row">
                <td>
                  {currentRank}º {getCrownIcon(currentRank)}
                </td>
                <td>{user.username}</td>
                <td>{user.score || 0}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {showModal && <ScoreSystemModal onClose={() => setShowModal(false)} />} {/* Mostrar el modal */}
    </div>
  );
};

export default UserRanking;
