import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../css/ClassItem.css";
import { useAuth } from "../contexts/AuthContext";
import CustomDialog from "./dialogs/CustomDialog";
import Swal from "sweetalert2";
import UserInfoModal from "./UserInfoModal";

const ClassItem = ({ classItem, onReserve, onCancel, onDelete }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [clients, setClients] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [isUserReserved, setIsUserReserved] = useState(false);
  const { user } = useAuth();
  const [showDialog, setShowDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserInWaitlist, setIsUserInWaitlist] = useState(false);

  const fetchClients = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${apiUrl}api/classes/${classItem.id}/clients`,
        config
      );

      const currentUser = localStorage.getItem("userId");

      const todos = response.data;
      const apuntados = todos.filter((c) => !c.in_waitlist);
      const enEspera = todos.filter((c) => c.in_waitlist);

      setClients(apuntados); // ⬅️ los apuntados se usan para avatares, etc
      setWaitlist(enEspera); // ⬅️ esto es nuevo, solo para el contador

      setIsUserReserved(
        todos.some((client) => client.id === parseInt(currentUser))
      );

      setIsUserInWaitlist(
        enEspera.some((client) => client.id === parseInt(currentUser))
      );
    } catch (error) {
      console.error("Error al obtener los clientes:", error);
    }
  }, [apiUrl, classItem.id]);

  useEffect(() => {
    fetchClients();
  }, [classItem.id, fetchClients]);

  // Método para cuando un Admin o Entrenador hace clic en un avatar de un cliente
  const handleClientClick = (client) => {
    if (user.role === "Admin" || user.role === "Entrenador") {
      // Llamar al método onClientClick y pasar los datos del cliente
      onClientClick(client);
    } else if (client.id === parseInt(user.userId)) {
      // Si el cliente es el usuario registrado, mostrar el mensaje de cancelar reserva
      handleCancel();
    }
  };

  /*   const handleCancel = async () => {
    if (isUserReserved) {
      Swal.fire({
        title: "",
        text: "¿Quieres cancelar tu reserva?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffcc00",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, cancélala",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onCancel(classItem.id);
          fetchClients();
        }
      });
    }
  }; */

  const handleCancel = async () => {
    const hayHuecoLibre = clients.length < classItem.max_attendees;

    // Si está en lista de espera y hay hueco, muestra los tres botones
    if (isUserInWaitlist && hayHuecoLibre) {
      Swal.fire({
        title: "",
        text: "¿Qué quieres hacer con tu reserva?",
        icon: "question",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Cancelar clase",
        denyButtonText: "Pasar a la clase",
        cancelButtonText: "Cerrar",
        confirmButtonColor: "#d33",
        denyButtonColor: "#ffcc00",
        cancelButtonColor: "#aaa",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onCancel(classItem.id);
          fetchClients();
        }

        if (result.isDenied) {
          await pasarAClase(classItem.id);
          fetchClients();
        }
      });
    } else {
      // Situación normal: solo cancelar o cerrar
      const now = new Date();
const classDate = new Date(`${classItem.class_date}T${classItem.start_time}`);
const minutesDiff = (classDate - now) / (1000 * 60);
const menosDe2Horas = minutesDiff <= 120;
Swal.fire({
  title: "",
  html: `
    <p>¿Quieres cancelar tu reserva?</p>
    ${
      menosDe2Horas
        ? "<p style='margin-top:8px; font-size:0.9rem; color:#aa0000'>⚠️ Al quedar menos de dos horas, la clase no se devolverá.</p>"
        : ""
    }
  `,
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#ffcc00",
  cancelButtonColor: "#d33",
  confirmButtonText: "Sí, cancélala",
}).then(async (result) => {
  if (result.isConfirmed) {
    await onCancel(classItem.id);
    fetchClients();
  }
});
    }
  };

  const pasarAClase = async (classId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.patch(
        `${apiUrl}api/reservations/${classId}/pasar-a-clase`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "¡Hecho!",
        text: "Has pasado a la clase correctamente.",
        timer: 2000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("❌ Error al mover a clase:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo mover a la clase.",
      });
    }
  };

  const onClientClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleViewProfile = () => {
    // Aquí puedes redirigir a la página del perfil del usuario
    console.log("Ver perfil del usuario", selectedUser.username);
  };

  const handleReserve = async (e) => {
    e.stopPropagation();
    await onReserve(classItem.id);
    fetchClients(); // Refresca la lista de clientes después de reservar
  };

  const handleDeleteClass = () => {
    setShowDialog(true); // Mostrar el diálogo personalizado
  };

  const handleDialogClose = async (option) => {
    setShowDialog(false); // Ocultar el diálogo
    if (option === "deleteOne") {
      await onDelete(classItem.id, false); // Solo borrar la clase actual
    } else if (option === "deleteAll") {
      await onDelete(classItem.id, true); // Borrar la clase y las recurrentes
    }
  };

  return (
    <div className="class-item">
      <div className="class-time">
        <p>
          <span
            className={`class-title ${
              classItem.title === "Fitboxing"
                ? "class-title-fitboxing"
                : "class-title-default"
            }`}
          >
            {classItem.title}
          </span>{" "}
          - De {classItem.start_time} a {classItem.end_time} |{" "}
          <span style={{ fontWeight: "bold" }}>
            {clients.length}/{classItem.max_attendees}
          </span>
          {waitlist.length > 0 && (
            <span
              style={{ marginLeft: "1rem", fontSize: "0.95rem", color: "#666" }}
            >
              — Lista de espera: {waitlist.length}/5
            </span>
          )}
        </p>
        {(user?.role === "Admin" || user?.role === "Entrenador") && (
          <button className="delete-class-button" onClick={handleDeleteClass}>
            <i className="fas fa-trash"></i> {/* Icono para eliminar clase */}
          </button>
        )}
      </div>
      <div className="class-details">
        <div className="clients-list">
          {clients.length > 0 ? (
            clients.map((client, index) => (
              <div
                key={index}
                className="client-avatar"
                onClick={() => handleClientClick(client)}
              >
                <img
                  src={`${client?.profilepicture}`}
                  alt={client.first_name}
                  className={client.in_waitlist ? "grayscale" : ""}
                  loading="lazy"
                  onError={(e) =>
                    (e.target.src =
                      "https://ui-avatars.com/api/?name=Cliente&background=random")
                  }
                />
              </div>
            ))
          ) : (
            <p>No hay clientes apuntados.</p>
          )}
        </div>

        {/* Lista de espera */}
        {waitlist.length > 0 && (
          <>
            <div className="waitlist-title">Lista de espera:</div>
            <div className="clients-list">
              {waitlist.map((client, index) => (
                <div
                  key={`w-${index}`}
                  className="client-avatar"
                  onClick={() => handleClientClick(client)}
                >
                  <img
                    src={`${client?.profilepicture}`}
                    alt={client.first_name}
                    className="grayscale"
                    loading="lazy"
                    onError={(e) =>
                      (e.target.src =
                        "https://ui-avatars.com/api/?name=Cliente&background=random")
                    }
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {!isUserReserved && (
          <button className="reserve-button" onClick={handleReserve}>
            <i className="fas fa-plus"></i>
          </button>
        )}
      </div>

      {showDialog && (
        <CustomDialog
          title="Confirmar Eliminación"
          message="¿Estás seguro de que deseas eliminar esta clase?"
          buttons={[
            { text: "Sí", onClick: () => handleDialogClose("deleteOne") },
            {
              text: "Sí y borrar recurrentes",
              onClick: () => handleDialogClose("deleteAll"),
            },
            {
              text: "Cancelar",
              onClick: () => setShowDialog(false),
              type: "cancel",
            },
          ]}
        />
      )}

      {showModal && selectedUser && (
        <UserInfoModal
          onClose={handleCloseModal}
          onViewProfile={handleViewProfile}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default ClassItem;
