import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importar los íconos
import '../css/Login.css'; // Asegúrate de tener los estilos importados
import Swal from 'sweetalert2';

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const { login } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    const userLogin = { username, password };
  
    try {
      const response = await axios.post(`${apiUrl}api/users/login`, userLogin);
      const { token, user } = response.data;
  
      // Guardar token en localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("username", user.username);
      localStorage.setItem("tipo_suscripcion", user.tipo_suscripcion);
      localStorage.setItem("role", user.role);
      localStorage.setItem("profilepicture", user.profilepicture);
  
      // Actualizar el estado de autenticación
      login(user.id, user.username, user.tipo_suscripcion, user.role, user.profilepicture);
  
      // Redirigir al perfil del usuario
      window.location.href = "/profile";
    } catch (error) {
      // Manejo de errores específicos
      if (error.response) {
        // Errores que provienen del servidor
        if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Usuario no encontrado. Por favor, verifica tu nombre de usuario o correo electrónico.",
            confirmButtonColor: "#ffcc00",
          });
        } else if (error.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Contraseña incorrecta. Por favor, verifica tu contraseña.",
            confirmButtonColor: "#ffcc00",
          });
        } else if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "",
            text: error.response.data.message,
            confirmButtonColor: "#ffcc00",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Error al iniciar sesión, por favor intenta de nuevo más tarde.",
            confirmButtonColor: "#ffcc00",
          });
        }
      } else {
        // Errores que no provienen del servidor (por ejemplo, problemas de red)
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error de conexión. Por favor, verifica tu conexión a internet e intenta de nuevo.",
          confirmButtonColor: "#ffcc00",
        });
      }
    }
  };
  

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Cambiar el estado para mostrar/ocultar contraseña
  };

  return (
    <div className="form-container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <label htmlFor="username">Usuario o E-mail</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <label htmlFor="password">Contraseña</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span className="password-toggle" onClick={toggleShowPassword}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        <button type="submit">Iniciar Sesión</button>
      </form>
      <div className="register-link">
        <p>
          ¿Olvidaste tu contraseña? <a href="/forgot-password">Recupérala aquí</a>.
        </p>
        <p>
          Si aún no tienes cuenta, puedes registrarte <a href="/register">aquí</a>.
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
