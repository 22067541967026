import React, { useState } from 'react';
import axios from 'axios';
import '../css/CreateUserForm.css';
import Swal from 'sweetalert2';

const CreateUserForm = ({ onCancel }) => { // Añadimos la prop onCancel
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('Cliente'); // Valor por defecto
    const [password, setPassword] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const handleCreateUser = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token'); // Obtener el token de autenticación
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const newUser = {
                username,
                first_name: firstName,
                last_name: lastName,
                email,
                role,
                password,
            };

            const response = await axios.post(`${apiUrl}api/users/create`, newUser, config);

            if (response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "",
                    text: "Usuario creado exitosamente",
                    confirmButtonColor: "#ffcc00",
                  });
                // Limpiar el formulario después de la creación exitosa
                setUsername('');
                setFirstName('');
                setLastName('');
                setEmail('');
                setRole('Cliente');
                setPassword('');
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: `Error: ${error.response.data.message}`,
                    confirmButtonColor: "#ffcc00",
                  });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: 'Error al crear el usuario',
                    confirmButtonColor: "#ffcc00",
                  });
            }
        }
    };

    return (
        <form className="create-user-form" onSubmit={handleCreateUser}>
            <div className="form-group">
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="firstName">Nombre:</label>
                <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="lastName">Apellidos:</label>
                <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>

            <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="password">Contraseña:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="role">Rol:</label>
                <select
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                >
                    <option value="Admin">Admin</option>
                    <option value="Entrenador">Entrenador</option>
                    <option value="Cliente">Cliente</option>
                </select>
            </div>

            <div className="form-actions">
                <button type="submit" className="create-button">Crear Usuario</button>
                <button type="button" className="cancel-button" onClick={onCancel}>Cancelar</button> {/* Botón de cancelar */}
            </div>
        </form>
    );
};

export default CreateUserForm;
