import React from "react";
import "../css/ScoreSystemModal.css";

const ScoreSystemModal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Sistema de Puntuación</h2>
        <table className="ranking-table">
          <thead>
            <tr>
              <th>Acción</th>
              <th>Puntos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asistir a una clase</td>
              <td>+1</td>
            </tr>
            <tr>
              <td>Subir una historia mencionándonos</td>
              <td>+1</td>
            </tr>
            <tr>
              <td>Mandarnos un video testimonio de 20/30 seg. contándonos como fue tu experiencia con nosotros</td>
              <td>+5</td>
            </tr>
            <tr>
              <td>Dejar una reseña de 5 estrellas en Google</td>
              <td>+10</td>
            </tr>
            <tr>
              <td>Traer a un amigo que se apunte a nuestro centro</td>
              <td>+20</td>
            </tr>

            {/* Añade más acciones y puntos aquí según el sistema que uses */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoreSystemModal;
