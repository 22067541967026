import React from 'react';
import '../css/SubscriptionCard.css';
import basicSub from "../content/basicSub.webp";
import mediumSub from "../content/mediumSub.webp";
import premiumSub from "../content/premiumSub.webp";

const SubscriptionCard = ({ imgSrc, title, subtitle, url, isSubscribed }) => {
    const handleSubscribe = () => {
        window.open(url, '_blank'); // Abre la URL en una nueva pestaña
    };

    return (
        <div className={`subscription-card ${isSubscribed ? 'subscribed' : ''}`}>
            <div className="subscription-card-img">
                <img src={imgSrc} alt={title} loading="lazy"/>
            </div>
            <div className="subscription-card-content">
                <h3>{title}</h3>
                <p>{subtitle}</p>
                <button onClick={handleSubscribe} disabled={isSubscribed}>
                    {isSubscribed ? 'Contratado' : 'Contratar'}
                </button>
            </div>
        </div>
    );
};

const SubscriptionList = ({ userSubscription }) => {
    return (
        <div className="subscription-list">
            <SubscriptionCard 
                imgSrc={basicSub}
                title="Plan Básico" 
                subtitle="10 clases por mes" 
                url="https://buy.stripe.com/4gwbLH7cSfXAbLi9B9" 
                isSubscribed={userSubscription === 'Basico'}
            />
            <SubscriptionCard 
                imgSrc={mediumSub}
                title="Plan Estándar" 
                subtitle="14 clases por mes" 
                url="https://buy.stripe.com/9AQeXT68O3aO16E3cF" 
                isSubscribed={userSubscription === 'Estandar'}
            />
            <SubscriptionCard 
                imgSrc={premiumSub}
                title="Plan Premium" 
                subtitle="Clases ilimitadas" 
                url="https://buy.stripe.com/28obLHap46n002A28C" 
                isSubscribed={userSubscription === 'Premium'}
            />
        </div>
    );
};

export default SubscriptionList;
