import React, { useState } from 'react';
import '../css/UserInfoModal.css'; // Asegúrate de agregar el archivo CSS para estilos
import EditUserModal from './EditUserModal'; // Importa el modal de edición

const UserInfoModal = ({ onClose, user }) => {
  const [showEditModal, setShowEditModal] = useState(false); // Controla la visibilidad del modal
  console.log(user)
  // Función para manejar la apertura del modal de edición de usuario
  const handleViewProfile = () => {
    setShowEditModal(true); // Abre el modal
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false); // Cierra el modal
  };

  const handleSave = () => {
    setShowEditModal(false); // Lógica para guardar los cambios y cerrar el modal
  };

  const handleToggleStatus = () => {
    setShowEditModal(false); // Lógica para el cambio de estado
  };

  return (
    <div>
      <div className="user-info-modal">
        <div className="user-info-modal-content">
          <span className="user-info-modal-close" onClick={onClose}>&times;</span>
          <img src={user.profilepicture} alt="Profile" className="user-info-modal-image" />
          <h2>{user.username}</h2>
          <p>{user.first_name} {user.last_name}</p>
          <p>{user.tipo_suscripcion ? `Plan: ${user.tipo_suscripcion}` : 'Sin suscripción'}</p>
          <div className="user-info-modal-buttons">
            <button className="user-info-modal-view-profile" onClick={handleViewProfile}>Modificar</button>
            <button className="user-info-modal-close-button" onClick={onClose}>Cerrar</button>
          </div>
        </div>
      </div>

      {showEditModal && (
        <EditUserModal
          user={user} // Pasa los detalles del usuario seleccionado
          onClose={handleCloseEditModal}
          onSave={handleSave}
          onToggleStatus={handleToggleStatus}
        />
      )}
    </div>
  );
};

export default UserInfoModal;
