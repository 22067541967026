import React, { useState } from 'react';
import axios from 'axios';
import '../css/EditUserModal.css'; // Puedes agregar estilos adicionales aquí
import Swal from 'sweetalert2';

const EditUserModal = ({ user, onClose, onSave, onToggleStatus }) => {
    const [maxClasses, setMaxClasses] = useState(user.max_classes);
    const [subscriptionType, setSubscriptionType] = useState(user.tipo_suscripcion);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSave = async () => {
        try {
          const token = localStorage.getItem('token');
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
      
          const updatedUser = {
            max_classes: maxClasses,
            tipo_suscripcion: subscriptionType,
          };
      
          const response = await axios.put(`${apiUrl}api/users/${user.id}/update`, updatedUser, config);
          
          // Verificar que la respuesta sea exitosa
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "",
              text: "Usuario actualizado exitosamente",
              confirmButtonColor: "#ffcc00",
            });
            onSave(); // Refresca la lista y cierra el modal
          }
        } catch (error) {
          // Mostrar error detallado en consola
          console.error('Error al actualizar el usuario:', error);
      
          // Si hay una respuesta del servidor, mostrar el mensaje de error al usuario
          if (error.response && error.response.data && error.response.data.message) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response.data.message,
              confirmButtonColor: "#ffcc00",
            });
          } else {
            // Si no hay respuesta del servidor o hay un error desconocido
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Hubo un problema al actualizar el usuario. Intenta nuevamente.",
              confirmButtonColor: "#ffcc00",
            });
          }
        }
      };

    const handleToggleStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const newStatus = user.status === 0 ? -1 : 0;
            await axios.put(`${apiUrl}api/users/${user.id}/status`, { status: newStatus }, config);
            onToggleStatus(); // Llama la función onToggleStatus pasada como prop para refrescar la lista y cerrar el modal
        } catch (error) {
            console.error('Error al cambiar el estado del usuario', error);
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h3>Editando Usuario: {user.username}</h3>
                <div className="form-group">
                    <label htmlFor="maxClasses">Máximo de clases:</label>
                    <input
                        type="number"
                        id="maxClasses"
                        value={maxClasses}
                        onChange={(e) => setMaxClasses(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="subscriptionType">Tipo de Suscripción:</label>
                    <select
                        id="subscriptionType"
                        value={subscriptionType}
                        onChange={(e) => setSubscriptionType(e.target.value)}
                    >
                        <option value="Sin suscripción">Sin suscripción</option>
                        <option value="Basico">Básico</option>
                        <option value="Estandar">Estándar</option>
                        <option value="Premium">Premium</option>
                        <option value="Reservado">Reservado</option>
                    </select>
                </div>

                <div className="form-actions">
                    <button className="save-button" onClick={handleSave}>Actualizar</button>
                    <button className="toggle-status-button" onClick={handleToggleStatus}>
                        {user.status === 0 ? 'Inactivar' : 'Activar'}
                    </button>
                    <button className="cancel-button" onClick={onClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default EditUserModal;
