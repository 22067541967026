// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-addPoints-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-addPoints-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.actions-section {
    margin-top: 20px;
}

.action-item {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    text-align: left;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ece8e8;
}

.points-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #ffcc00;
}

.points-button:hover {
    color: #e6b800;
}


/* Responsividad */
@media (max-width: 768px) {
  .modal-addPoints-content h2 {
    font-size: 1.2em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/AddPointsModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;;AAGA,kBAAkB;AAClB;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".modal-addPoints-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal-addPoints-content {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 500px;\n    width: 100%;\n    position: relative;\n}\n\n.modal-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    cursor: pointer;\n    font-size: 20px;\n}\n\n.actions-section {\n    margin-top: 20px;\n}\n\n.action-item {\n    display: flex;\n    /*justify-content: space-between;*/\n    align-items: center;\n    text-align: left;\n    margin-bottom: 15px;\n    padding: 10px;\n    border-radius: 5px;\n    background-color: #ece8e8;\n}\n\n.points-button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    font-size: 24px;\n    color: #ffcc00;\n}\n\n.points-button:hover {\n    color: #e6b800;\n}\n\n\n/* Responsividad */\n@media (max-width: 768px) {\n  .modal-addPoints-content h2 {\n    font-size: 1.2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
