import React, { useState } from 'react';
import CalendarSelector from './CalendarSelector';
import WeekSelector from './WeekSelector';
import '../css/CalendarWeekSelector.css';

const ClassMonthPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isCalendarVisible, setIsCalendarVisible] = useState(false); // Estado para controlar la visibilidad del calendario en móviles

    const handleSelectDay = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        const selectedDate = new Date(year, month - 1, day);
        setSelectedDate(selectedDate);
    };

    const toggleCalendarVisibility = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };

    return (
        <div className="main-container">
            <div className="calendar-toggle-button">
                <button onClick={toggleCalendarVisibility}>
                    <i className="fas fa-calendar-alt"></i>
                </button>
            </div>
            <div className={`calendar-container ${isCalendarVisible ? 'visible' : ''}`}>
                <CalendarSelector onSelectDay={handleSelectDay} />
            </div>
            <div className="week-selector-container">
                <WeekSelector selectedDate={selectedDate} onSelectDay={handleSelectDay} />
            </div>
        </div>
    );
};

export default ClassMonthPage;
