import React, { useState, useEffect } from "react";
import "../css/FilterPanel.css";

const FilterPanel = ({
  onClose,
  onApplyFilter,
  initialUsernameFilter,
  initialUserTypes,
  initialSubscriptionTypes,
}) => {
  const [usernameFilter, setUsernameFilter] = useState("");
  const [isUserTypeOpen, setIsUserTypeOpen] = useState(false);
  const [isSubscriptionTypeOpen, setIsSubscriptionTypeOpen] = useState(false);

  // Estados para tipo de usuario y tipo de suscripción
  const [userTypes, setUserTypes] = useState({
    Cliente: true,
    Entrenador: true,
    Admin: true,
  });

  const [subscriptionTypes, setSubscriptionTypes] = useState({
    "Sin suscripción": true,
    Basico: true,
    Estandar: true,
    Premium: true,
    Reservado: true,
  });

  useEffect(() => {
    // Establecer los filtros iniciales si existen
    setUsernameFilter(initialUsernameFilter);
    setUserTypes(initialUserTypes);
    setSubscriptionTypes(initialSubscriptionTypes);
  }, [initialUsernameFilter, initialUserTypes, initialSubscriptionTypes]);

  const toggleUserTypeOpen = () => {
    setIsUserTypeOpen((prev) => !prev);
  };

  const toggleSubscriptionTypeOpen = () => {
    setIsSubscriptionTypeOpen((prev) => !prev);
  };

  const handleUserTypeChange = (type) => {
    setUserTypes((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleSubscriptionTypeChange = (type) => {
    setSubscriptionTypes((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleApplyFilter = () => {
    onApplyFilter(usernameFilter, userTypes, subscriptionTypes);
    onClose();
  };

  // Función para resetear los filtros
  const handleResetFilters = () => {
    // Resetear los filtros a los valores iniciales o a todos marcados
    setUsernameFilter("");
    setUserTypes({
      Cliente: true,
      Entrenador: true,
      Admin: true,
    });
    setSubscriptionTypes({
      "Sin suscripción": true,
      Basico: true,
      Estandar: true,
      Premium: true,
      Reservado: true,
    });

    // Aplicar el filtro sin restricciones para mostrar todo
    onApplyFilter("", {
      Cliente: true,
      Entrenador: true,
      Admin: true,
    }, {
      "Sin suscripción": true,
      Basico: true,
      Estandar: true,
      Premium: true,
      Reservado: true,
    });

    onClose(); // Cierra el panel
  };

  return (
    <div className="filter-panel">
      <div className="filter-panel-header">
        <h2>Filtros</h2>
        <button onClick={onClose} className="close-btn">
          &times;
        </button>
      </div>
      <div className="filter-panel-body">
        <label htmlFor="username-filter">Username:</label>
        <input
          type="text"
          id="username-filter"
          placeholder="Introduce el nombre de usuario"
          value={usernameFilter}
          onChange={(e) => setUsernameFilter(e.target.value)}
        />

        {/* Acordeón de tipo de usuario */}
        <div className="accordion">
          <div className="accordion-header" onClick={toggleUserTypeOpen}>
            <span>Tipo de usuario</span>
            <span>{isUserTypeOpen ? "▲" : "▼"}</span>
          </div>
          {isUserTypeOpen && (
            <div className="accordion-content">
              {Object.keys(userTypes).map((type) => (
                <div key={type} className="checkbox-item">
                  <input
                    type="checkbox"
                    id={type}
                    checked={userTypes[type]}
                    onChange={() => handleUserTypeChange(type)}
                  />
                  <label htmlFor={type}>{type}</label>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Acordeón de tipo de suscripción */}
        <div className="accordion">
          <div
            className="accordion-header"
            onClick={toggleSubscriptionTypeOpen}
          >
            <span>Tipo de suscripción</span>
            <span>{isSubscriptionTypeOpen ? "▲" : "▼"}</span>
          </div>
          {isSubscriptionTypeOpen && (
            <div className="accordion-content">
              {Object.keys(subscriptionTypes).map((type) => (
                <div key={type} className="checkbox-item">
                  <input
                    type="checkbox"
                    id={type}
                    checked={subscriptionTypes[type]}
                    onChange={() => handleSubscriptionTypeChange(type)}
                  />
                  <label htmlFor={type}>{type}</label>
                </div>
              ))}
            </div>
          )}
        </div>

        <button className="apply-filter-btn" onClick={handleApplyFilter}>
          Aplicar Filtro
        </button>

        {/* Nuevo botón para borrar filtros */}
        <button className="reset-filter-btn" onClick={handleResetFilters}>
          Borrar Filtros
        </button>
      </div>
    </div>
  );
};

export default FilterPanel;
