import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/ScheduleClass.css";
import Swal from 'sweetalert2';

const ScheduleClass = ({ onClose }) => {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [maxClients, setMaxClients] = useState("");
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [isRecurring, setIsRecurring] = useState(false); // Estado para el checkbox de clase recurrente
  const [title, setTitle] = useState(""); // Nuevo estado para el título de la clase
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch trainers from the API
    const fetchTrainers = async () => {
      try {
        const token = localStorage.getItem("token"); // Asumiendo que el token está en localStorage
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${apiUrl}api/users/trainers`, config); // Ruta para obtener entrenadores
        setTrainers(response.data);
      } catch (error) {
        console.error("Error al obtener entrenadores:", error);
      }
    };

    fetchTrainers();
  }, [apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    // Validar el título, si está vacío o solo contiene espacios, se establece como "Fitboxing"
    const validTitle = title.trim() === "" ? "Fitboxing" : title;
    const classData = {
      trainer_id: selectedTrainer,
      class_date: date,
      start_time: startTime,
      end_time: endTime,
      max_attendees: maxClients,
      title: validTitle, // Añadir el título al objeto classData
      isRecurring,
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${apiUrl}api/classes/add`,
        classData,
        config
      );

      // Mostrar mensaje según la respuesta del backend
      Swal.fire({
        icon: "success",
        title: "",
        text: response.data.message,
        confirmButtonColor: "#ffcc00",
      });
      onClose();
    } catch (error) {
      if (error.response) {
        // Si el servidor responde con un estado fuera del rango 2xx
        Swal.fire({
          icon: "error",
          title: "",
          text: `Error: ${error.response.data.message}`,
          confirmButtonColor: "#ffcc00",
        });
      } else if (error.request) {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error: No se recibió respuesta del servidor.",
          confirmButtonColor: "#ffcc00",
        });
        // Si no se recibe respuesta del servidor
      } else {
        // Otros errores
        Swal.fire({
          icon: "error",
          title: "",
          text: `Error: ${error.message}`,
          confirmButtonColor: "#ffcc00"
        });
      }
      console.error("Error al programar la clase:", error);
    }
  };

  return (
    <div className="schedule-class-container">
      <div className="schedule-class-form">
        <h2>Programar Clase</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">Título de la Clase:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Fitboxing" // Este campo es obligatorio
          />

          <label htmlFor="date">Fecha:</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />

          <label htmlFor="startTime">Hora de Inicio:</label>
          <input
            type="time"
            id="startTime"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          />

          <label htmlFor="endTime">Hora de Finalización:</label>
          <input
            type="time"
            id="endTime"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
          />

          <label htmlFor="maxClients">Máximo de Clientes:</label>
          <input
            type="number"
            id="maxClients"
            value={maxClients}
            onChange={(e) => setMaxClients(e.target.value)}
            min="1"
            required
          />

          <label htmlFor="trainer">Entrenador:</label>
          <select
            id="trainer"
            value={selectedTrainer}
            onChange={(e) => setSelectedTrainer(e.target.value)}
            required
          >
            <option value="">Selecciona un entrenador</option>
            {trainers.map((trainer) => (
              <option key={trainer.id} value={trainer.id}>
                {trainer.first_name} {trainer.last_name}
              </option>
            ))}
          </select>

          <div className="recurring-container">
            <input
              type="checkbox"
              id="recurring"
              checked={isRecurring}
              onChange={(e) => setIsRecurring(e.target.checked)}
            />
            <label htmlFor="recurring" className="recurring-label">
              Programar clase recurrente
            </label>
          </div>

          <div className="form-buttons">
            <button type="submit">Programar</button>
            <button type="button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleClass;
