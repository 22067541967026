import React from 'react';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado
import '../css/UserCard.css';

const UserCard = ({ user, onEdit, onDelete, onAddPoints }) => {
    const userStatusClass = user.status === -1 ? 'inactive' : ''; // Clase condicional para usuarios inactivos
    return (
        <div className={`user-card ${userStatusClass}`}>
            <div className="user-card-info">
                <h3>{user.username}</h3>
                <p>{user.first_name} {user.last_name}</p>
                <p><strong>Role: </strong>{user.role}</p> {/* Añadido el rol del usuario */}
                {/* Renderiza los botones solo si el usuario no es Admin */}
                {user.role !== 'Admin' && (
                    <div className="user-card-actions">
                        <button className="edit-button" onClick={() => onEdit(user.id)}>
                            <FaEdit />
                        </button>
                        <button className="edit-button" onClick={() => onAddPoints(user)}>
                            <FaPlusCircle  />
                        </button>
                        <button className="delete-button" onClick={() => onDelete(user.id)}>
                            <FaTrash />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserCard;
