import { useEffect, useRef, useState } from "react";
import "../css/HomePage.css";
import resena1 from "../content/reseña1.png";
import resena2 from "../content/reseña2.png";
import resena3 from "../content/reseña3.png";

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const sliderRef = useRef(null);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const nombreCliente = name.trim();
    const mensaje = `¡Hola! Mi nombre es ${nombreCliente}. Me gustaría reservar una clase gratis.`;
    const whatsappUrl = `https://wa.me/+34675215620?text=${encodeURIComponent(
      mensaje
    )}`;

    window.location.href = whatsappUrl;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;

        if (scrollLeft + clientWidth >= scrollWidth - 5) {
          // Si llega al final, vuelve al inicio
          sliderRef.current.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          sliderRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="homepage-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1 className="hero-title">La revolución del entrenamiento!</h1>
        <p className="hero-subtitle">
          Consigue resultados y disfruta al mismo tiempo.
        </p>
        <p className="hero-description">
          Entrena en grupos reducidos de máximo 6 personas, combinando fuerza y
          fitboxing. Comenzamos con 30 minutos de ejercicios de fuerza y
          finalizamos con 20 minutos de fitboxing en el saco. Siempre guiado por
          un entrenador personal que adapta el entrenamiento a ti y cuida tu
          técnica.
        </p>
        <button className="hero-button" onClick={handleOpenPopup}>
          Prueba una clase gratis
        </button>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="benefits-slider-wrapper">
          <div className="benefits-slider" ref={sliderRef}>
            <div className="benefit-slide">
              <h3 className="benefit-heading">Entrena y Disfruta</h3>
              <p className="benefit-text">
                Te divertirás entrenando y conseguirás ser constante.
              </p>
            </div>
            <div className="benefit-slide">
              <h3 className="benefit-heading">Gana Masa Muscular</h3>
              <p className="benefit-text">
                Verás progreso real semana a semana.
              </p>
            </div>
            <div className="benefit-slide">
              <h3 className="benefit-heading">Pierde Grasa</h3>
              <p className="benefit-text">
                Reduce tu porcentaje graso con cada sesión.
              </p>
            </div>
            <div className="benefit-slide">
              <h3 className="benefit-heading">Más Energía</h3>
              <p className="benefit-text">
                Te sentirás mejor y con más vitalidad en tu día a día.
              </p>
            </div>
            <div className="benefit-slide">
              <h3 className="benefit-heading">Quema Calorías</h3>
              <p className="benefit-text">
                Hasta 700 calorías por entrenamiento.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Horario Section */}
      <section
        className="schedule-section"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "2rem 1rem",
          textAlign: "center",
        }}
      >
        <h2
          className="schedule-title"
          style={{ fontSize: "1.8rem", marginBottom: "0.5rem" }}
        >
          🕒 Horario de Clases
        </h2>
        <p
          className="schedule-text"
          style={{ fontSize: "1.1rem", color: "#444" }}
        >
          Lunes a viernes de 7:30 a 10:30 y de 16:30 a 21:30
          <br />
          Lunes y jueves de 14:15 a 15:15
        </p>
      </section>

      {/* Reseñas Section */}
      <section className="reviews-section">
        <h2 className="reviews-title">¿Qué opinan nuestros clientes?</h2>
        <div className="reviews-container">
          <div className="review-card">
            <img
              src={resena1}
              alt="Reseña 1"
              className="review-image"
            />
          </div>
          <div className="review-card">
            <img
              src={resena2}
              alt="Reseña 2"
              className="review-image"
            />
          </div>
          <div className="review-card">
            <img
              src={resena3}
              alt="Reseña 3"
              className="review-image"
            />
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2 className="cta-title">¡Únete a la revolución del fitness!</h2>
        <p className="cta-subtitle">
          Reserva tu clase de prueba ahora y descubre el poder del
          entrenamiento.
        </p>
        <button className="cta-button" onClick={handleOpenPopup}>
          Prueba una clase gratis
        </button>
      </section>

      {/* Popup Form */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2 className="popup-title">Reserva tu clase gratis</h2>
            <p>Déjanos tu nombre y hablamos por WhatsApp</p>
            <form className="popup-form" onSubmit={handleSubmit}>
              <label className="popup-label">Nombre:</label>
              <input
                type="text"
                className="popup-input"
                placeholder="Tu nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <button type="submit" className="popup-submit">
                Reservar
              </button>
            </form>
            <button className="popup-close" onClick={handleClosePopup}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
