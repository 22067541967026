import React from 'react';
import '../css/HeaderFooter.css';

const FooterPage = () => {
    const currentYear = new Date().getFullYear(); // Obtener el año actual

    return (
        <footer>
            <p>© {currentYear} Wild Fitboxing. Todos los derechos reservados.</p>
        </footer>
    );
};

export default FooterPage;