import React, { useState } from "react";
import axios from "axios";
import '../css/Login.css'; // Reutiliza los estilos del login

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${apiUrl}api/users/forgot-password`, {
        email,
      });

      setMessage("Si existe una cuenta asociada, recibirás un correo para restablecer tu contraseña.");
    } catch (error) {
      console.error("Error al enviar solicitud de restablecimiento:", error);
      setMessage("Ocurrió un error. Por favor, inténtalo de nuevo.");
    }
  };

  return (
    <div className="form-container">
      <h2>Recuperar Contraseña</h2>
      <form onSubmit={handleForgotPassword}>
        <label htmlFor="email">Correo Electrónico</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <button type="submit">Enviar Solicitud</button>
      </form>
      {message && <p className="message">{message}</p>}
      <div className="login-link">
        <p>
          ¿Ya la has recuperado? <a href="/login">Inicia sesión aquí</a>.
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
