import React from 'react';
import '../../css/CustomDialog.css'; // Asegúrate de tener los estilos

const CustomDialog = ({ title, message, buttons }) => {
    return (
      <div className="dialog-overlay">
        <div className="dialog-container">
          <h3>{title}</h3>
          <p>{message}</p>
          <div className="dialog-buttons">
            {buttons.map((button, index) => (
              <button
                key={index}
                className={`dialog-button ${button.type || ''}`}
                onClick={button.onClick}
              >
                {button.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default CustomDialog;
