import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/HeaderPage';
import Footer from './components/FooterPage';
import RegisterPage from './components/RegisterPage';
import LoginPage from './components/LoginPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import './App.css';
import UserProfilePage from './components/UserProfilePage';
import ClassMonthPage from './components/ClassMonthPage';
import CreateUserForm from './components/CreateUserForm';
import ContactPage from './components/ContactPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import UserRanking from './components/UserRanking';

const AppRoutes = () => {
    const { isAuthenticated, user } = useAuth();

    // Muestra un mensaje de carga mientras se determina el estado de autenticación
    if (isAuthenticated === null) {
        return <div>Cargando...</div>;
    }

    return (
        <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/profile" /> : <Navigate to="/login" />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            <Route path="/profile" element={isAuthenticated ? <UserProfilePage /> : <Navigate to="/login" />} />
            <Route path="/ranking" element={isAuthenticated ? <UserRanking /> : <Navigate to="/login" />} />
            <Route path="/classMonth" element={isAuthenticated ? <ClassMonthPage /> : <Navigate to="/login" />} />
            <Route path="/createUser" element={isAuthenticated && user?.role === 'Admin' ? <CreateUserForm /> : <Navigate to="/login" />} />
            <Route path="/contact" element={<ContactPage />} />
        </Routes>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <div className="content">
                    <AppRoutes />
                </div>
                <Footer />
            </Router>
        </AuthProvider>
        
    );
};

export default App;
