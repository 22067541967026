import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import UserCard from "./UserCard";
import CreateUserForm from "./CreateUserForm";
import EditUserModal from "./EditUserModal"; // Importa el componente EditUserModal
import "../css/UserCard.css";
import Swal from "sweetalert2";
import { FaUserPlus, FaRedo, FaFilter } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import FilterPanel from "./FilterPanel";
import AddPointsModal from './AddPointsModal'; // Importa el modal para añadir puntos

const UserCardList = () => {
  const [users, setUsers] = useState([]);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Estado para el usuario en edición
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showAddPointsModal, setShowAddPointsModal] = useState(false); // Estado para controlar el modal de añadir puntos

  const [selectedUser, setSelectedUser] = useState(null); // Almacenar usuario seleccionado

  // Guardamos los valores de los filtros seleccionados
  const [usernameFilter, setUsernameFilter] = useState(""); // Para el filtro de username
  const [userTypes, setUserTypes] = useState({
    Cliente: true,
    Entrenador: true,
    Admin: true,
  }); // Para el filtro de tipo de usuario
  const [subscriptionTypes, setSubscriptionTypes] = useState({
    "Sin suscripción": true,
    Basico: true,
    Estandar: true,
    Premium: true,
    Reservado: true,
  }); // Para el filtro de tipo de suscripción

  const apiUrl = process.env.REACT_APP_API_URL;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const fetchUsers = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${apiUrl}api/users/all`, config);
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error al obtener los usuarios", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleEdit = (userId) => {
    const userToEdit = users.find((user) => user.id === userId);
    if (userToEdit) {
      setEditingUser(userToEdit);
    }
  };

  const handleSave = () => {
    fetchUsers(); // Refresca la lista de usuarios
    setEditingUser(null); // Cierra el modal
  };

  const handleToggleStatus = () => {
    fetchUsers(); // Refresca la lista de usuarios
    setEditingUser(null); // Cierra el modal
  };

  const handleConfirmDelete = async (userId) => {
    Swal.fire({
      title: "",
      text: "¿Quieres eliminar a este usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffcc00",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminalo",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await handleDelete(userId)
      }
    });  
  }

  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Llamar al backend para eliminar el usuario y sus reservas
      const response = await axios.delete(
        `${apiUrl}api/users/${userId}`,
        config
      );

      Swal.fire({
        icon: "success",
        title: "",
        text: response.data.message,
        confirmButtonColor: "#ffcc00",
      });

      fetchUsers();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "",
        text: error.response.data.message,
        confirmButtonColor: "#ffcc00",
      });
      console.error("Error al eliminar el usuario", error);
    }
  };

  const handleCreateUser = () => {
    setIsCreatingUser(true);
  };

  const handleCancelCreateUser = () => {
    setIsCreatingUser(false);
  };

  const handleManageClasses = async () => {
    try {
      const confirmRestore = window.confirm(
        "¿Estás seguro de que deseas restaurar las clases para todos los usuarios?"
      );
      if (!confirmRestore) return;

      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Llamar al backend para restaurar las clases
      const response = await axios.put(
        `${apiUrl}api/users/restore-classes`,
        {},
        config
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: "Clases restauradas con éxito para todos los usuarios.",
          confirmButtonColor: "#ffcc00",
        });
        fetchUsers();
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Hubo un problema al restaurar las clases.",
          confirmButtonColor: "#ffcc00",
        });
      }
    } catch (error) {
      console.error("Error al restaurar las clases", error);
      Swal.fire({
        icon: "error",
        title: "",
        text: "Error al restaurar las clases. Inténtalo de nuevo más tarde.",
        confirmButtonColor: "#ffcc00",
      });
    }
  };

  const toggleFilterPanel = () => {
    setShowFilterPanel((prevState) => !prevState); // Abre o cierra el panel de filtros
  };

  const handleApplyFilter = (usernameFilter, userTypes, subscriptionTypes) => {
    let updatedUsers = [...users];

    // Filtrar por nombre de usuario
    if (usernameFilter) {
      updatedUsers = updatedUsers.filter((user) =>
        user.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    // Filtrar por tipos de usuario seleccionados
    const selectedUserTypes = Object.keys(userTypes).filter(
      (type) => userTypes[type]
    );
    if (selectedUserTypes.length > 0) {
      updatedUsers = updatedUsers.filter((user) =>
        selectedUserTypes.includes(user.role)
      );
    }

    // Filtrar por tipos de suscripción seleccionados
    const selectedSubscriptionTypes = Object.keys(subscriptionTypes).filter(
      (type) => subscriptionTypes[type]
    );

    if (selectedSubscriptionTypes.length > 0) {
      updatedUsers = updatedUsers.filter((user) => {
        if (
          user.tipo_suscripcion === null ||
          user.tipo_suscripcion === undefined ||
          user.tipo_suscripcion === ""
        ) {
          return subscriptionTypes["Sin suscripción"];
        }
        return selectedSubscriptionTypes.includes(user.tipo_suscripcion);
      });
    }

    // Guardar los valores de los filtros aplicados
    setUsernameFilter(usernameFilter);
    setUserTypes(userTypes);
    setSubscriptionTypes(subscriptionTypes);

    // Actualizar la lista filtrada
    setFilteredUsers(updatedUsers);
  };

  const handleAddPoints = (user) => {
    setSelectedUser(user);
    setShowAddPointsModal(true); // Abre el modal
  };
  

  return (
    <div>
      {isCreatingUser ? (
        <CreateUserForm onCancel={handleCancelCreateUser} />
      ) : (
        <>
          <div className="user-card-actions-top">
            {isMobile ? (
              <>
                <button onClick={handleCreateUser}>
                  <FaUserPlus size={24} />
                </button>
                <button onClick={handleManageClasses}>
                  <FaRedo size={24} />
                </button>
                <button onClick={toggleFilterPanel}>
                  <FaFilter size={24} />
                </button>
              </>
            ) : (
              <>
                <button onClick={handleCreateUser}>Crear usuario</button>
                <button onClick={handleManageClasses}>Restaurar clases</button>
                <button onClick={toggleFilterPanel}>
                  <FaFilter />
                </button>
              </>
            )}
          </div>
          <p style={{ color: "black", fontSize: "20px", marginTop: "10px" }}>
            Usuarios: {filteredUsers.length}
          </p>
          <div className="user-card-list">
            {filteredUsers
              .sort((a, b) => a.username.localeCompare(b.username))
              .map((user) => (
                <UserCard
                  key={user.id}
                  user={user}
                  onEdit={handleEdit}
                  onDelete={handleConfirmDelete}
                  onAddPoints={handleAddPoints} // Pasa la función para abrir el modal
                />
              ))}
          </div>
        </>
      )}
      {editingUser && (
        <EditUserModal
          user={editingUser}
          onClose={() => setEditingUser(null)}
          onSave={handleSave}
          onToggleStatus={handleToggleStatus}
        />
      )}
      {showFilterPanel && (
        <FilterPanel
          onClose={toggleFilterPanel}
          onApplyFilter={handleApplyFilter}
          initialUsernameFilter={usernameFilter}
          initialUserTypes={userTypes}
          initialSubscriptionTypes={subscriptionTypes}
        />
      )}
      {showAddPointsModal && selectedUser && (
        <AddPointsModal
          user={selectedUser}
          onClose={() => setShowAddPointsModal(false)} // Cerrar el modal
        />
      )}
    </div>
  );
};

export default UserCardList;
