import React, { useState } from "react";
import '../css/AcceptTermsModal.css'; 
import TermsModal from "./TermsModal";

const AcceptTermsModal = ({ onAccept, onLogout }) => {
    const [showTermsModal, setShowTermsModal] = useState(false);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Para continuar, debes aceptar los Términos y Condiciones</h3>
        <p>
          Lee y acepta nuestros{" "}
          <span className="terms-link" onClick={() => setShowTermsModal(true)}>
            Términos y Condiciones
          </span>{" "}
          para poder seguir utilizando la plataforma.
        </p>
        <div className="modal-actions">
          <button onClick={onAccept} className="accept-button">
            Aceptar
          </button>
          <button onClick={onLogout} className="logout-button">
            Salir
          </button>
        </div>
      </div>
      {showTermsModal && (
        <TermsModal onClose={() => setShowTermsModal(false)} /> // Muestra el modal si está activo
      )}
    </div>
  );
};

export default AcceptTermsModal;
