// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-classes-page {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-classes-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

.classes-list {
    list-style: none;
    padding: 0;
}

.class-item {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
}

.class-item p {
    margin: 5px 0;
    color: #333;
}

.class-item strong {
    color: #ffcc00;
}
`, "",{"version":3,"sources":["webpack://./src/css/UserClassesPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,qCAAqC;AACzC;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".user-classes-page {\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.user-classes-page h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n    font-family: 'Montserrat', sans-serif;\n}\n\n.classes-list {\n    list-style: none;\n    padding: 0;\n}\n\n.class-item {\n    background-color: #fff;\n    border: 1px solid #ddd;\n    padding: 15px;\n    border-radius: 5px;\n    margin-bottom: 15px;\n    font-family: 'Montserrat', sans-serif;\n}\n\n.class-item p {\n    margin: 5px 0;\n    color: #333;\n}\n\n.class-item strong {\n    color: #ffcc00;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
