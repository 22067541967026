// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-page {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    min-height: 100vh;
}

.contact-page h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #000;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.5em;
}

.contact-item p {
    margin: 0;
}

.contact-icon {
    color: #ffcc00;
    font-size: 2em;
}

.contact-item a {
    text-decoration: none;
    color: #000;
}

.contact-item a:hover {
    text-decoration: underline;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .contact-page {
        padding: 10px;
    }

    .contact-page h2 {
        font-size: 1.8em;
    }

    .contact-item {
        font-size: 1.2em;
    }
}

@media (max-width: 480px) {
    .contact-page h2 {
        font-size: 1.5em;
    }

    .contact-item {
        font-size: 1em;
        flex-direction: column;
        text-align: center;
    }

    .contact-icon {
        font-size: 1.5em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/ContactPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,wBAAwB;AACxB;IACI;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;QACd,sBAAsB;QACtB,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".contact-page {\n    padding: 20px;\n    text-align: center;\n    background-color: #f9f9f9;\n    min-height: 100vh;\n}\n\n.contact-page h2 {\n    font-size: 2em;\n    margin-bottom: 20px;\n    color: #000;\n}\n\n.contact-info {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n}\n\n.contact-item {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    font-size: 1.5em;\n}\n\n.contact-item p {\n    margin: 0;\n}\n\n.contact-icon {\n    color: #ffcc00;\n    font-size: 2em;\n}\n\n.contact-item a {\n    text-decoration: none;\n    color: #000;\n}\n\n.contact-item a:hover {\n    text-decoration: underline;\n}\n\n/* Estilos responsivos */\n@media (max-width: 768px) {\n    .contact-page {\n        padding: 10px;\n    }\n\n    .contact-page h2 {\n        font-size: 1.8em;\n    }\n\n    .contact-item {\n        font-size: 1.2em;\n    }\n}\n\n@media (max-width: 480px) {\n    .contact-page h2 {\n        font-size: 1.5em;\n    }\n\n    .contact-item {\n        font-size: 1em;\n        flex-direction: column;\n        text-align: center;\n    }\n\n    .contact-icon {\n        font-size: 1.5em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
