import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/UserProfile.css";
import Swal from "sweetalert2";
import { FaExclamationCircle } from "react-icons/fa";
import { subscribeToPush } from "../utils/subscribeToPush";

const UserProfile = ({ user }) => {
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [phoneNumber, setPhoneNumber] = useState(user.mobile_number || ""); // Nuevo campo para número de teléfono
  const [tipoSuscripcion] = useState(user.tipo_suscripcion || "");
  const [notificacionesActivadas, setNotificacionesActivadas] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const estadoGuardado = localStorage.getItem("pushEnabled") === "true";
    setNotificacionesActivadas(estadoGuardado);
  }, []);

  useEffect(() => {
    const checkPushStatus = async () => {
      const token = localStorage.getItem("token");
      try {
        const res = await fetch(`${apiUrl}api/notifications/status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        setNotificacionesActivadas(data.enabled);
        localStorage.setItem("pushEnabled", data.enabled ? "true" : "false");
      } catch (err) {
        console.error(
          "❌ Error al comprobar el estado de notificaciones:",
          err
        );
      }
    };

    checkPushStatus();
  }, [apiUrl]);


  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const updatedData = {
        first_name: firstName,
        last_name: lastName,
        mobile_number: phoneNumber, // Incluimos el número de teléfono en los datos a actualizar
      };

      const response = await axios.put(
        `${apiUrl}api/users/profile`,
        updatedData,
        config
      );

      Swal.fire({
        icon: "success",
        title: "",
        text: response.data.message,
        confirmButtonColor: "#ffcc00",
      });
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);

      Swal.fire({
        icon: "error",
        title: "",
        text: "Error al actualizar el perfil",
        confirmButtonColor: "#ffcc00",
      });
    }
  };

  const handleTogglePush = async () => {
    const token = localStorage.getItem("token");

    if (!notificacionesActivadas) {
      const result = await subscribeToPush();
      if (result) {
        setNotificacionesActivadas(true);
        localStorage.setItem("pushEnabled", "true");

        Swal.fire({
          icon: "success",
          title: "¡Notificaciones activadas!",
          text: "Recibirás avisos cuando haya plazas libres.",
          timer: 2500,
          showConfirmButton: false,
        });
      }
    } else {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();

        await fetch(`${apiUrl}api/notifications/unsubscribe`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ endpoint: subscription?.endpoint }),
        });

        localStorage.setItem("pushEnabled", "false");
        setNotificacionesActivadas(false);

        Swal.fire({
          icon: "info",
          title: "Notificaciones desactivadas",
          text: "No recibirás avisos sobre nuevas plazas.",
          timer: 2500,
          showConfirmButton: false,
        });
      } catch (err) {
        console.error("❌ Error al eliminar la suscripción:", err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al desactivar las notificaciones.",
        });
      }
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-info">
        <div className="name-fields">
          <div className="editable-field">
            <label htmlFor="firstName">Nombre:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {!firstName && <FaExclamationCircle className="warning-icon" />}
          </div>
          <div className="editable-field">
            <label htmlFor="lastName">Apellidos:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {!lastName && <FaExclamationCircle className="warning-icon" />}
          </div>
          <div className="editable-field">
            <label htmlFor="phoneNumber">Número de Teléfono:</label>{" "}
            {/* Nuevo campo para número de teléfono */}
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {!phoneNumber && <FaExclamationCircle className="warning-icon" />}
          </div>
        </div>
        <div className="email-max-fields">
          <div className="non-editable-field email-field">
            <label>Email:</label>
            <input type="text" value={user.email} readOnly />
          </div>
{/*           <div className="non-editable-field max-classes-field">
            <label>Max Clases:</label>
            <input type="text" value={user.max_classes} readOnly />
          </div> */}
          <div className="non-editable-field">
            <label>Tipo de Suscripción:</label>
            <input type="text" value={tipoSuscripcion} readOnly />
          </div>
        </div>
        <div className="push-content">
          <label htmlFor="pushToggle">
            Recibir notificaciones:
            <input
              type="checkbox"
              id="pushToggle"
              className="checkbox-custom"
              checked={notificacionesActivadas}
              onChange={handleTogglePush}
            />
          </label>
        </div>
        <button className="save-button" onClick={handleSave}>
          Guardar
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
