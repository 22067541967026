import React from 'react';
import { FaTimes, FaPlusCircle } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2'; // Importa Swal para mostrar alertas
import "../css/AddPointsModal.css";

const AddPointsModal = ({ user, onClose, onAddPoints }) => {
  const apiUrl = process.env.REACT_APP_API_URL; // La URL de tu API

  const handleAddPoints = async (points) => {
    try {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // Asegúrate de que la URL esté bien construida
        const response = await axios.post(`${apiUrl}api/users/${user.id}/add-points`, { points }, config);

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Puntos agregados correctamente',
                confirmButtonColor: '#ffcc00',
            });
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Error al agregar puntos',
            text: error.response?.data?.message || 'Error en el servidor',
            confirmButtonColor: '#ffcc00',
        });
    }
};

  return (
    <div className="modal-addPoints-overlay">
      <div className="modal-addPoints-content">
        <button className="modal-close" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Añadir puntos a {user.username}</h2>

        <div className="actions-section">
          {/* Acción 1: Asistir a una clase */}
          <div className="action-item">
            <button
              className="points-button"
              onClick={() => handleAddPoints(1)}
            >
              <FaPlusCircle />
            </button>
            <span>Asistir a una clase</span>
          </div>

          {/* Acción 2: Subir una historia mencionándonos */}
          <div className="action-item">
            <button
              className="points-button"
              onClick={() => handleAddPoints(1)}
            >
              <FaPlusCircle />
            </button>
            <span>Subir una historia mencionándonos</span>
          </div>

          {/* Acción 3: Mandar un video testimonio */}
          <div className="action-item">
            <button
              className="points-button"
              onClick={() => handleAddPoints(5)}
            >
              <FaPlusCircle />
            </button>
            <span>
              Mandarnos un video testimonio de 20/30 seg. contándonos cómo fue
              tu experiencia con nosotros
            </span>
          </div>

          {/* Acción 4: Dejar una reseña de 5 estrellas en Google */}
          <div className="action-item">
            <button
              className="points-button"
              onClick={() => handleAddPoints(10)}
            >
              <FaPlusCircle />
            </button>
            <span>Dejar una reseña de 5 estrellas en Google</span>
          </div>

          {/* Acción 5: Traer a un amigo que se apunte */}
          <div className="action-item">
            <button
              className="points-button"
              onClick={() => handleAddPoints(20)}
            >
              <FaPlusCircle />
            </button>
            <span>Traer a un amigo que se apunte a nuestro centro</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPointsModal;
