import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import '../css/UserClassesPage.css';

const UserClassesPage = () => {
    const { user } = useAuth();
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                let response;
                if (user.role === 'Cliente') {
                    response = await axios.get(`${apiUrl}api/reservations/my-classes`, config);
                } else if (user.role === 'Entrenador') {
                    response = await axios.get(`${apiUrl}api/classes/my-classes`, config);
                }

                setClasses(response.data);
                setLoading(false);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    if (user.role === 'Cliente') {
                        setError('Aún no te has apuntado a ninguna clase.');
                    } else if (user.role === 'Entrenador') {
                        setError('Aún no estás asignado a ninguna clase.');
                    }
                } else {
                    setError('Error al obtener las clases.');
                }
                setLoading(false);
            }
        };

        fetchClasses();
    }, [user, apiUrl]);

    if (loading) {
        return <p>Cargando...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="user-classes-page">
            <h2>Mis Clases</h2>
            {classes.length > 0 ? (
                <ul className="classes-list">
                    {classes
                        .sort((a, b) => a.start_time.localeCompare(b.start_time)) // Ordenar por hora de inicio
                        .map((classItem) => (
                            <li key={classItem.id} className="class-item">
                                <p><strong>Fecha:</strong> {classItem.class_date}</p>
                                <p><strong>Hora:</strong> {classItem.start_time} - {classItem.end_time}</p>
                                {user.role === 'Entrenador' && (
                                    <p><strong>Clientes inscritos:</strong> {classItem.clients.map(client => client.username).join(', ')}</p>
                                )}
                            </li>
                        ))}
                </ul>
            ) : (
                <p>No tienes clases programadas.</p>
            )}
        </div>
    );
};

export default UserClassesPage;
