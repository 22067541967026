// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    text-align: center;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .terms-link {
    color: #ffcc00;
    cursor: pointer;
    font-weight: bold;
  }
  
  .accept-button,
  .logout-button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .accept-button {
    background-color: #4caf50;
    color: white;
  }
  
  .logout-button {
    background-color: #f44336;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/AcceptTermsModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,iBAAiB;EACnB;;EAEA;;IAEE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    max-width: 500px;\n    text-align: center;\n  }\n  \n  .modal-actions {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 20px;\n  }\n  \n  .terms-link {\n    color: #ffcc00;\n    cursor: pointer;\n    font-weight: bold;\n  }\n  \n  .accept-button,\n  .logout-button {\n    padding: 10px 20px;\n    cursor: pointer;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n  }\n  \n  .accept-button {\n    background-color: #4caf50;\n    color: white;\n  }\n  \n  .logout-button {\n    background-color: #f44336;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
