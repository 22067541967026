import React from 'react';
import { FaWhatsapp, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import '../css/ContactPage.css'; // Asegúrate de crear este archivo para los estilos

const ContactPage = () => {
    return (
        <div className="contact-page">
            <h2>Contacto</h2>
            <div className="contact-info">
                <div className="contact-item">
                    <FaWhatsapp className="contact-icon" />
                    <p><a href="https://wa.me/624055631" target="_blank" rel="noopener noreferrer">624 055 631</a></p>
                </div>
                <div className="contact-item">
                    <FaInstagram className="contact-icon" />
                    <p><a href="https://www.instagram.com/wildfitboxing.aldaia" target="_blank" rel="noopener noreferrer">@wildfitboxing.aldaia</a></p>
                </div>
                <div className="contact-item">
                    <FaMapMarkerAlt className="contact-icon" />
                    <p>
                        <a 
                            href="https://www.google.com/maps/search/?api=1&query=Calle+Pablo+Iglesias,+2+(bajo),+Aldaia,+España" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Calle Pablo Iglesias, 2(bajo), Aldaia, España
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
