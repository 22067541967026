import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../css/ResetPasswordPage.css"; // Asegúrate de crear este archivo para los estilos
import Swal from "sweetalert2";

const ResetPasswordPage = () => {
  const { token } = useParams(); // Capturamos el token directamente
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Las contraseñas no coinciden.",
        confirmButtonColor: "#ffcc00",
      });
      return;
    }
    console.log(token);
    try {
      const response = await axios.post(
        `${apiUrl}api/users/reset-password/${token}`,
        {
          password,
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: "Contraseña restablecida con éxito.",
          confirmButtonColor: "#ffcc00",
        });
        navigate("/login");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Error al restablecer la contraseña:",
        confirmButtonColor: "#ffcc00",
      });
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="form-container">
      <h2>Restablecer Contraseña</h2>
      <form onSubmit={handlePasswordReset}>
        <label htmlFor="password">Nueva Contraseña</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span className="password-toggle" onClick={toggleShowPassword}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        <label htmlFor="confirmPassword">Confirmar Contraseña</label>
        <div className="password-container">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span className="password-toggle" onClick={toggleShowConfirmPassword}>
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        <button className="btnReset" type="submit">
          Restablecer Contraseña
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
