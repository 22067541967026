import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importar los íconos
import '../css/Login.css'; // Asegúrate de tener los estilos importados
import Swal from 'sweetalert2';
import TermsModal from './TermsModal';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
    const [acceptTerms, setAcceptTerms] = useState(false); // Estado para aceptar los términos
    const [role] = useState('Cliente');
    const [max_classes] = useState(1);
    const [showTermsModal, setShowTermsModal] = useState(false); // Estado para mostrar el modal
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleRegister = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${apiUrl}api/users/register`, {
                username,
                email,
                password,
                role,
                max_classes
            });
            Swal.fire({
                icon: "success",
                title: "",
                text: 'Usuario registrado exitosamente',
                confirmButtonColor: "#ffcc00",
              });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Mostrar el mensaje de error que viene del backend
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: error.response.data.message,
                    confirmButtonColor: "#ffcc00",
                  });
            } else {
                // Manejar otros tipos de errores
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: 'Error en el registro. Inténtalo nuevamente.',
                    confirmButtonColor: "#ffcc00",
                  });
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword); // Cambiar el estado para mostrar/ocultar contraseña
    };

    return (
        <div className="form-container">
            <h2>REGÍSTRATE</h2>
            <form onSubmit={handleRegister}>
                <label htmlFor="username">Nombre de Usuario</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                
                <label htmlFor="email">Correo Electrónico</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <label htmlFor="password">Contraseña</label>
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span className="password-toggle" onClick={toggleShowPassword}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                </div>

                <div className="terms-container">
                    <input
                        type="checkbox"
                        id="accept-terms"
                        checked={acceptTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                        required
                    />
                     <label htmlFor="accept-terms">
                        Acepto los{" "}
                        <span
                            className="terms-link"
                            onClick={() => setShowTermsModal(true)} // Abrir el modal
                        >
                            términos y condiciones
                        </span>
                    </label>
                </div>

                <button type="submit" disabled={!acceptTerms}>Crear Cuenta</button>
            </form>
            <p className="login-link-text">
                O si ya estás registrado, inicia sesión <Link to="/login" className="login-link">pulsando aquí</Link>.
            </p>

            {showTermsModal && (
                <TermsModal onClose={() => setShowTermsModal(false)} /> // Muestra el modal si está activo
            )}
        </div>
    );
};

export default Register;
