// src/components/InstallButton.js
import React, { useState, useEffect } from 'react';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detecta si el dispositivo es móvil
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile(); // Ejecuta la detección inmediatamente
    window.addEventListener('resize', checkIfMobile);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  if (!deferredPrompt || !isMobile) {
    return null; // No mostrar nada si no es un dispositivo móvil o si no se ha capturado el evento `beforeinstallprompt`
  }

  return (
    <button id="install-button" onClick={handleInstallClick} style={styles.button}>
      Instalar Aplicación
    </button>
  );
};

const styles = {
    button: {
      backgroundColor: '#ffcc00',
      color: '#000',
      border: 'none',
      padding: '10px 20px',
      cursor: 'pointer',
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: '700',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto', // Alinea el botón a la derecha dentro del nav
    },
  };

export default InstallButton;
