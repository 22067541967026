import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "../css/HeaderFooter.css";
import logo from "../content/WildFitBoxingLogo.png";
import InstallButton from "./InstallButton";

const HeaderPage = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  const handleUserButtonClick = () => {
    if (isAuthenticated) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const nav = document.querySelector('nav');
    const navToggle = document.querySelector('.nav-toggle');
    const closeButton = document.querySelector('.close-button');

    const handleToggle = () => {
      nav.classList.toggle('active');
    };

    const handleClose = () => {
      nav.classList.remove('active');
    };

    navToggle.addEventListener('click', handleToggle);

    if (closeButton) {
      closeButton.addEventListener('click', handleClose);
    }

    const navLinks = document.querySelectorAll('nav a');
    navLinks.forEach(link => {
      link.addEventListener('click', handleClose);
    });

    return () => {
      navToggle.removeEventListener('click', handleToggle);
      if (closeButton) {
        closeButton.removeEventListener('click', handleClose);
      }
      navLinks.forEach(link => {
        link.removeEventListener('click', handleClose);
      });
    };
  }, []);

  return (
    <header>
      <img src={logo} alt="Wild Fitboxing Logo" className="logo"/>
      <h1>Wild Fitboxing</h1>
      <button className="nav-toggle" aria-label="Toggle navigation">
        &#9776;
      </button>
      <nav>
        <Link to="/">Inicio</Link>
        {isAuthenticated && <Link to="/classMonth" onClick={() => document.querySelector('nav').classList.remove('active')}>Clases</Link>}
        {isAuthenticated && <Link to="/ranking" onClick={() => document.querySelector('nav').classList.remove('active')}>Ranking</Link>}
        <Link to="/contact">Contacto</Link>
        <InstallButton />
        <button className="close-button" aria-label="Cerrar menú">&#10006;</button> {/* Botón para cerrar el menú */}
      </nav>
      <button className="user-icon" onClick={handleUserButtonClick}>
        {isAuthenticated && user?.username ? (
          <img className="img-icon-user"
            src={`${user.profilepicture}`}
            alt="Profile"
            loading="lazy"
            onError={(e) => (e.target.src = "https://via.placeholder.com/60")}
          />
        ) : (
          <i className="fas fa-user"></i>
        )}
      </button>
    </header>
  );
};

export default HeaderPage;
