import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ClassItem from "./ClassItem";
import ScheduleClass from "./ScheduleClass";
import { useAuth } from "../contexts/AuthContext"; // Importa el contexto de autenticación
import Swal from 'sweetalert2';

const WeekSelector = ({ selectedDate, onSelectDay }) => {
  const { user } = useAuth(); // Obtén el usuario autenticado
  const [currentWeek, setCurrentWeek] = useState(selectedDate);
  const [classes, setClasses] = useState([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setCurrentWeek(selectedDate);
  }, [selectedDate]);

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: selectedDate.toLocaleDateString("en-CA"),
        },
      };

      const response = await axios.get(`${apiUrl}api/classes/bydate`, config);
      setClasses(response.data);
    } catch (error) {
      console.error("Error al obtener las clases:", error);
    }
  }, [apiUrl, selectedDate]);

  useEffect(() => {
    fetchClasses();
  }, [selectedDate, fetchClasses]);

  const nextWeek = () => {
    const next = new Date(currentWeek);
    next.setDate(next.getDate() + 7);
    setCurrentWeek(next);
  };

  const prevWeek = () => {
    const prev = new Date(currentWeek);
    prev.setDate(prev.getDate() - 7);
    setCurrentWeek(prev);
  };

  const getStartOfWeek = (date) => {
    const dayOfWeek = date.getDay();
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const start = new Date(date);
    start.setDate(date.getDate() + diff);
    return start;
  };

  const startOfWeek = getStartOfWeek(currentWeek);
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(startOfWeek);
    day.setDate(day.getDate() + i);
    return day;
  });

  const handleSelectDay = (day) => {
    const formattedDate = day.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    onSelectDay(formattedDate);
  };

  const monthName = startOfWeek.toLocaleString("default", { month: "long" });

  // Formatear la fecha seleccionada
  const formatSelectedDate = (date) => {
    return date.toLocaleDateString("es-ES", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const handleReserve = async (classId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${apiUrl}api/reservations/${classId}/reserve`,
        {},
        config
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: response.data.message,
          confirmButtonColor: "#ffcc00",
        });
        fetchClasses(); // Refresca las clases después de la reserva
      }
    } catch (error) {

      console.error(error)

      if (error.response && error.response.data.message) {
        Swal.fire({
          icon: "error",
          title: "",
          text: `${error.response.data.message}`,
          confirmButtonColor: "#ffcc00",
        });
      } else {
        console.error("Error al reservar la clase:", error);
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error al reservar la clase. Por favor, inténtelo de nuevo más tarde.",
          confirmButtonColor: "#ffcc00",
        });
      }
    }
  };

  const handleCancelReservation = async (classId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const currentUserId = localStorage.getItem("userId");

      const response = await axios.delete(
        `${apiUrl}api/reservations/${classId}/cancel`,
        {
          ...config,
          data: { userId: currentUserId },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: "Reserva cancelada con éxito",
          confirmButtonColor: "#ffcc00",
        });
        fetchClasses(); // Refresca las clases después de la cancelación
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Hubo un problema al cancelar la reserva.",
          confirmButtonColor: "#ffcc00",
        });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        Swal.fire({
          icon: "error",
          title: "",
          text: `Error: ${error.response.data.message}`,
          confirmButtonColor: "#ffcc00",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error al cancelar la reserva. Por favor, inténtelo de nuevo más tarde.",
          confirmButtonColor: "#ffcc00",
        });
      }
    }
  };

  const handleDeleteClass = async (classId, deleteRecurrent = false) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Pasar el parámetro deleteRecurrent en la solicitud
      const response = await axios.delete(
        `${apiUrl}api/classes/${classId}?deleteRecurrent=${deleteRecurrent}`,
        config
      );
      // Manejar la respuesta del servidor
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: response.data.message,
          confirmButtonColor: "#ffcc00",
        });

        // Actualizar la lista de clases en el frontend
        setClasses((prevClasses) =>
          prevClasses.filter((c) =>
            deleteRecurrent
              ? c.id !== classId && c.recurring_id !== classId
              : c.id !== classId
          )
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "No se pudo eliminar la clase. Por favor, inténtelo de nuevo.",
          confirmButtonColor: "#ffcc00",
        });
      }
    } catch (error) {
      console.error("Error al eliminar la clase:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          icon: "error",
          title: "",
          text: `Error: ${error.response.data.message}`,
          confirmButtonColor: "#ffcc00",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: "Error al eliminar la clase. Por favor, inténtelo de nuevo más tarde.",
          confirmButtonColor: "#ffcc00",
        });
      }
    }
  };

  return (
    <div className="week-selector-container">
      <div className="week-selector">
        <button onClick={prevWeek}>&lt;</button>
        <span>
          Semana del {startOfWeek.getDate()} al {endOfWeek.getDate()} de{" "}
          {monthName}
        </span>
        <button onClick={nextWeek}>&gt;</button>
        <div className="week-days">
          {weekDays.map((day, index) => (
            <button
              key={index}
              className="day-button"
              onClick={() => handleSelectDay(day)}
            >
              {day.toLocaleDateString("es-ES", { weekday: "short" })}{" "}
              {day.getDate()}
            </button>
          ))}
        </div>
      </div>

      {["Admin", "Entrenador"].includes(user.role) && ( // Condición para mostrar el botón
        <button className="new-class-button" onClick={openScheduleModal}>
          <span className="icon">+</span> Programar clase
        </button>
      )}

      <div className="selected-date">
        <h3>{formatSelectedDate(selectedDate)}</h3>
      </div>

      <div className="class-list">
        {classes.length > 0 ? (
          classes
            .sort((a, b) => a.start_time.localeCompare(b.start_time))
            .map((classItem) => (
              <ClassItem
                key={classItem.id}
                classItem={classItem}
                onReserve={handleReserve}
                onCancel={handleCancelReservation}
                onDelete={handleDeleteClass} // Pasa la función de eliminar clase
              />
            ))
        ) : (
          <p>No hay clases programadas para este día.</p>
        )}
      </div>

      {isScheduleModalOpen && <ScheduleClass onClose={closeScheduleModal} />}
    </div>
  );
};

export default WeekSelector;
